import Vue from "vue";
import App from "./App.vue";
import VueNativeSock from "vue-native-websocket";
import VuePlyr from "vue-plyr";
import "vue-plyr/dist/vue-plyr.css";
import { sync } from "vuex-router-sync";
import store from "./store";
import router from "./router";
import { WS_URL } from "./config";

sync(store, router); // done. Returns an unsync callback fn

Vue.config.productionTip = false;

Vue.use(VuePlyr, {
  plyr: {}
});

Vue.use(VueNativeSock, WS_URL, {
  store: store,
  format: "json",
  reconnection: true, // (Boolean) whether to reconnect automatically (false)
  reconnectionAttempts: 5, // (Number) number of reconnection attempts before giving up (Infinity),
  reconnectionDelay: 3000 // (Number) how long to initially wait before attempting a new (1000)
});

async function start() {
  await store.dispatch("getContent");
  //await store.dispatch("getThemes");

  new Vue({
    store,
    router,
    render: h => h(App)
  }).$mount("#app");
}

start();
