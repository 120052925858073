<template>
  <div class="users">
    <svg
      v-for="user in $store.state.users"
      :key="user.id"
      :style="{
        fill: $store.getters.settings.color,
        left: user.pos * 100 + '%'
      }"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      width="100px"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 357.373 357.373"
      xml:space="preserve"
    >
      <defs></defs>
      <path
        :style="{
          stroke: $store.getters.settings.backgroundColor,
          strokeWidth: '10px'
        }"
        d="M256.525,233.831c-9.843-4.337-21-9.251-21.415-12.685c-1.764-14.58,4.656-22.362,13.541-33.133
	c11.972-14.512,26.871-32.574,26.871-74.024c0-32.64-7.907-60.333-22.867-80.085C235.858,11.724,210.28,0,178.687,0
	c-31.593,0-57.172,11.724-73.969,33.904c-14.959,19.752-22.867,47.445-22.867,80.085c0,41.451,14.899,59.512,26.871,74.024
	c8.885,10.771,15.305,18.554,13.541,33.133c-0.415,3.433-11.572,8.348-21.415,12.685c-32.6,14.362-87.162,38.399-87.162,118.542
	c0,2.761,2.239,5,5,5h320c2.761,0,5-2.239,5-5C343.687,272.23,289.125,248.193,256.525,233.831z"
      />
    </svg>
  </div>
</template>

<script>
export default {
  name: "users"
};
</script>

<style scoped lang="scss">
.users {
  pointer-events: none;
  z-index: 1000;
  width: 100%;
  position: fixed;
  bottom: 0;
  height: 100px;

  svg {
    transition: all 0.2s ease;
    position: absolute;
    opacity: 0.25;
    filter: blur(15px);
  }
}
</style>
