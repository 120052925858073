<template>
  <div style="display:inline-block;">
    <component
      :is="text.link ? 'a' : 'span'"
      :href="text.link"
      v-for="(text, index) in texts"
      :key="index"
      :style="{
        fontFamily: text.fontFamily,
        color: text.color,
        //fontSize: text.fontSize + 'px',
        fontWeight: text.isBold ? 'bold' : '',
        fontStyle: text.isItalic ? 'italic' : '',
        backgroundColor: text.backgroundColor
      }"
      >{{ text.body }}</component
    >
  </div>
</template>
<script>
export default {
  name: "styledText",
  props: {
    texts: Array,
    default: () => []
  }
};
</script>
