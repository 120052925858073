var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('nav',{staticClass:"program"},_vm._l((_vm.$store.state.programs),function(program,index){return _c('router-link',{key:index + program.title,staticClass:"program-link",style:({
      fontFamily: program.films[0].fontFamily
    }),attrs:{"to":{
      name: 'Program',
      params: {
        program: program.title,
        film: undefined
      }
    }}},[_c('span',[_vm._v(_vm._s(program.title))])])}),1)}
var staticRenderFns = []

export { render, staticRenderFns }