import Vue from "vue";
import Vuex from "vuex";
//import _ from "lodash";
import { API_URL } from "../config";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    themes: [],
    users: [],
    manual: false,
    settings: {
      color: "black",
      backgroundColor: "white",
      marquee: false,
      frames: false,
      blinking: true,
      fontFamily: "arial"
    },
    socket: {
      isConnected: false,
      message: "",
      reconnectError: false
    },
    programs: [],
    films: [] //films.slice(0, 50)
  },
  getters: {
    films(state) {
      return state.programs.reduce(
        (films, program) => [
          ...films,
          ...program.films.map(f => ({ ...f, program: program.title }))
        ],
        []
      );
    },
    allFonts(state, getters) {
      return getters.films.reduce(
        (fonts, { fontFamily }) =>
          fonts.filter(f => f !== fontFamily).concat([fontFamily]),
        []
      );
    },
    settings(state, getters, { route: { params } }) {
      if (state.manual || !getters.selectedFilm || !params.program)
        return state.settings;
      const { color, backgroundColor, fontFamily } = getters.selectedFilm;
      return { ...state.settings, color, backgroundColor, fontFamily };
    },
    selectedFilmId(state, getters, { route: { params } }) {
      return params.film || null || getters.films[0].id;
    },
    selectedProgramId(state, getters, { route: { params } }) {
      return params.program || null || state.programs[0].title;
    },
    selectedProgram(state, getters) {
      return state.programs.find(f => f.title === getters.selectedProgramId);
    },
    selectedFilm(state, getters) {
      return getters.films.find(
        f =>
          f.id === getters.selectedFilmId &&
          f.program === getters.selectedProgramId
      );
    },
    currentIndex(state, getters) {
      return getters.selectedProgram.films.findIndex(
        f => f.id === getters.selectedFilmId
      );
    },
    upNext(state, getters) {
      return getters.selectedProgram.films.filter(
        (f, i) => i > getters.currentIndex
      );
    },
    nextFilm(state, getters) {
      const next = getters.currentIndex + 1;
      return next >= getters.selectedProgram.films.length
        ? null
        : getters.selectedProgram.films[next];
    },
    prevFilm(state, getters) {
      const prev = getters.currentIndex - 1;
      return prev < 0 ? null : getters.selectedProgram.films[prev];
    }
  },
  mutations: {
    SET_MANUAL(state, manual) {
      state.manual = manual;
    },
    /*SELECT_FILM(state, id) {
      state.selectedFilmId = id;
      this.commit("SET_THEME", state.themes[_.random(state.themes.length - 1)]);
    },*/
    SOCKET_ONOPEN(state, event) {
      Vue.prototype.$socket = event.currentTarget;
      state.socket.isConnected = true;
    },
    SOCKET_ONCLOSE(state) {
      state.socket.isConnected = false;
    },
    SOCKET_ONERROR(state, event) {
      console.error(state, event);
    },
    // default handler called for all methods
    SOCKET_ONMESSAGE(state, message) {
      //console.log(message)
      state.socket.message = message;
      if (message.key === "theme") {
        this.commit("ADD_THEME", message);
      }
      if (message.key === "connection") {
        this.commit("ADD_USER", message);
      }
      if (message.key === "content") {
        this.commit("SET_PROGRAMS", message.programs);
      }
      if (message.type === "disconnected") {
        this.commit("REMOVE_USER", message.id);
      }
    },
    // mutations for reconnect methods
    SOCKET_RECONNECT(state, count) {
      Vue.set(state, "users", []);
      console.info(state, count);
    },
    SOCKET_RECONNECT_ERROR(state) {
      state.socket.reconnectError = true;
    },
    ADD_THEME(state, theme) {
      Vue.set(state, "themes", [
        ...state.themes.filter(t => t.id !== theme.id),
        theme
      ]);
    },
    ADD_USER(state, user) {
      Vue.set(state, "users", [
        ...state.users.filter(t => t.id !== user.id),
        user
      ]);
    },
    REMOVE_USER(state, id) {
      Vue.set(
        state,
        "users",
        state.users.filter(u => u.id !== id)
      );
    },
    SET_THEMES(state, themes) {
      Vue.set(state, "themes", themes);
    },
    SET_THEME(state, theme) {
      Vue.set(state, "settings", theme);
    },
    SET_THEME_SETTING(state, { setting, value }) {
      Vue.set(state.settings, setting, value);
    },
    SET_PROGRAMS(state, value) {
      Vue.set(state, "programs", value);
    }
  },
  actions: {
    sendMessage: function(context, message) {
      Vue.prototype.$socket.send(message);
    },
    sendPosition(context, message) {
      Vue.prototype.$socket.send(message);
    },
    getContent(context) {
      return fetch(API_URL + "/getContent")
        .then(res => res.json())
        .then(data => {
          context.commit("SET_PROGRAMS", data.programs);
        });
    }
  },
  modules: {}
});
