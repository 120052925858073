var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"display":"inline-block"}},_vm._l((_vm.texts),function(text,index){return _c(text.link ? 'a' : 'span',{key:index,tag:"component",style:({
      fontFamily: text.fontFamily,
      color: text.color,
      //fontSize: text.fontSize + 'px',
      fontWeight: text.isBold ? 'bold' : '',
      fontStyle: text.isItalic ? 'italic' : '',
      backgroundColor: text.backgroundColor
    }),attrs:{"href":text.link}},[_vm._v(_vm._s(text.body))])}),1)}
var staticRenderFns = []

export { render, staticRenderFns }