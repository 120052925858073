<template>
  <player
    v-if="$store.getters.selectedFilm.video_url"
    class="stream"
    :src="$store.getters.selectedFilm.video_url"
    autoplay
    @ended="next"
  >
    <template v-slot:info="{ isPlaying }">
      <div class="box">
        <programs class="playernav" />
      </div>
      <div class="box">
        <h1>
          {{ $store.getters.selectedFilm.title }} by
          {{ $store.getters.selectedFilm.artist }}
        </h1>
      </div>
      <transition name="fade">
        <div class="box description" v-if="!isPlaying">
          <styled-text :texts="$store.getters.selectedFilm.description" />
        </div>
      </transition>
    </template>
    <template v-slot:sidebar="{ isPlaying }">
      <transition name="fade">
        <films v-if="!isPlaying" />
      </transition>
    </template>
  </player>
  <div v-else-if="$store.getters.selectedFilm.youtube_url">
    <div class="videoWrapper">
      <iframe
        :src="$store.getters.selectedFilm.youtube_url"
        width="560"
        autoplay
        height="349"
        allowfullscreen
      ></iframe>
    </div>
  </div>
  <div v-else-if="$store.getters.selectedFilm.video_url_alt" class="stream">
    <img
      :src="$store.getters.selectedFilm.video_url_alt"
      :alt="$store.getters.selectedFilm.title"
    />
  </div>
  <div v-else></div>
</template>

<script>
import Films from "./films.vue";
import Player from "./Player.vue";
import Programs from "./programs.vue";
import StyledText from "./styledText.vue";
export default {
  name: "Stream",
  components: { Player, Films, Programs, StyledText },
  methods: {
    next(event) {
      console.log(event);
      this.$router.push({
        name: "Work",
        params: { film: this.$store.getters.nextFilm.id }
      });
    }
  },
  watch: {
    videoSource() {
      this.$refs.plyr.player.source = this.videoSource;
    }
  },
  computed: {},
  mounted() {}
};
</script>

<style lang="scss" scoped>
.stream {
  height: auto !important;
  max-width: 100%;
  //min-height: 40vh;
  --plyr-color-main: var(--color) !important;
  --plyr-video-background: var(--background-color) !important;

  img {
    width: 100%;
    max-width: 100%;
  }
}

.films a {
  padding-right: 5em;
}

.playernav {
  font-size: 1.25rem;
}
.box {
  display: table;
  padding: 0;
  margin: 0;
  background-color: var(--background-color);
  h1 {
    padding: 0;
    margin: 0;
  }

  &.description {
    white-space: pre-wrap;
  }
}

.fullscreen {
  nav {
    align-items: flex-start;
    flex-flow: row wrap;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.videoWrapper {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  height: 0;
}
.videoWrapper iframe {
  position: absolute;
  border: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
</style>
