var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"films"},_vm._l((_vm.$store.getters.selectedProgram.films),function(film){return _c('router-link',{key:film.id,staticClass:"film",style:({
      '--item-background-color': film.backgroundColor,
      '--item-color': film.color,
      fontFamily: film.fontFamily
    }),attrs:{"to":{
      name: 'Work',
      params: { film: film.id, program: _vm.$store.getters.selectedProgram.title }
    }}},[_c('small',{staticStyle:{"float":"right","margin-right":"-.5em"}},[_vm._v(" "+_vm._s(film.duration))]),_c('p',{staticClass:"filmtitle"},[_c('span',[_vm._v(_vm._s(film.title))])]),(film.artist)?_c('p',[_vm._v(_vm._s(film.artist))]):_vm._e()])}),1)}
var staticRenderFns = []

export { render, staticRenderFns }