<template>
  <div class="container">
    <!--<div>22<span class="blinking green">:</span>35</div>-->
    <programs class="responsive programs" />
    <films
      class="films"
      v-if="$store.getters.selectedProgram.films.length > 1"
    />
    <div class="content">
      <stream class="stream"></stream>

      <div class="textcontent">
        <h2>{{ $store.getters.selectedFilm.title }}</h2>
        <p class="workinfo">
          <template v-if="$store.getters.selectedFilm.artist"
            >{{ $store.getters.selectedFilm.artist }} <br
          /></template>
          <span v-if="$store.getters.selectedFilm.year">{{
            $store.getters.selectedFilm.year
          }}</span>
          <span v-if="$store.getters.selectedFilm.extraInfo"
            >{{ $store.getters.selectedFilm.extraInfo }}></span
          >
          <span v-if="$store.getters.selectedFilm.duration"
            >{{ $store.getters.selectedFilm.duration }} min</span
          >
        </p>

        <div>
          <p class="text">
            <styled-text :texts="$store.getters.selectedFilm.description" />
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Films from "../components/films.vue";
import Stream from "../components/Stream.vue";
import Programs from "../components/programs";
import StyledText from "../components/styledText.vue";
export default {
  components: { Stream, Films, Programs, StyledText },
  name: "Work",
  props: {
    film: String,
    program: String
  },
  methods: {}
};
</script>

<style lang="scss">
.container {
  grid-column-gap: 5rem;
  grid-row-gap: 1rem;
  grid-gap: 1rem;
  max-width: 1280px;
  padding-bottom: 10em;
  //C transition: all 0.1s ease;
  //padding: 0 1rem 6rem 1rem;
  //padding: 5px;
  margin: 0 auto auto;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto auto;

  .workinfo {
    span + span {
      &:before {
        content: ", ";
      }
    }
  }
  .text {
    max-width: 600px;
    padding-right: 1em;
    line-height: 1.5;
  }
  .programs {
    margin-top: 0.5em;
  }

  @media screen and (min-width: 1024px) {
    grid-template-columns: 5fr 1fr;
    grid-template-rows: auto 1fr;

    .content {
      grid-column: 1;
      .stream {
        //position: sticky;
        //top: 0.5em;
        //height: 100vh;
      }
    }

    .title {
      grid-column: 1;
    }

    .textcontent {
      position: sticky;
      top: 1em;
    }
    .films {
      grid-row-start: 2;
      grid-row-end: 3;
      grid-column: 2;
    }
  }
}
</style>
