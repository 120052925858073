<template>
  <div
    id="app"
    :class="{
      'with-frames': settings.frames,
      'with-blinking': settings.blinking
    }"
  >
    <!--<body class="with-frames with-marquee with-blinking">-->
    <!--<settings />-->

    <router-view />

    <users />
    <link
      v-if="$store.getters.settings.fontFamily"
      :href="
        '//fonts.googleapis.com/css?family=' + $store.getters.allFonts.join('|')
      "
      rel="stylesheet"
      type="text/css"
    />
  </div>
</template>

<script>
import Users from "@/components/users";
import { throttle } from "lodash";

export default {
  name: "App",
  methods: {
    sendPosition(clientX) {
      this.$socket.sendObj({
        action: "sendPosition",
        attrs: { pos: clientX / document.body.clientWidth }
      });
    },
    throttledSendPosition: throttle(
      function(pos) {
        this.sendPosition(pos);
      },
      100,
      { leading: true, trailing: true }
    ),
    mousemove({ clientX }) {
      this.throttledSendPosition(clientX - 50);
    },
    touchmove({ touches }) {
      this.throttledSendPosition(touches[0].clientX - 50);
    },
    setBodyStyle({ color, backgroundColor, fontFamily }) {
      document.body.style.setProperty("--color", color);
      document.body.style.setProperty("--background-color", backgroundColor);
      document.body.style.fontFamily = fontFamily;

      //document.body.style.color = color;
      //document.body.style.backgroundColor = backgroundColor;
    }
  },
  watch: {
    settings: {
      deep: true,
      handler({ color, backgroundColor, fontFamily }) {
        this.setBodyStyle({ color, backgroundColor, fontFamily });
      }
    }
  },
  computed: {
    settings() {
      return this.$store.getters.settings;
    }
  },
  data() {
    return {
      noise: true
    };
  },
  created() {
    this.setBodyStyle(this.$store.getters.settings);
  },
  mounted() {
    //this.$options.sockets.onmessage = (data) => console.log({data})
    window.addEventListener("mousemove", this.mousemove);
    window.addEventListener("touchmove", this.touchmove);
  },
  components: { Users }
};
</script>

<style lang="scss">
html {
  overflow-y: scroll;
}
.title,
.program-link {
  //color: var(--color);

  text-decoration: inherit !important;

  h1 {
    margin: 0;
    margin-top: 0;
  }
}

html,
body {
  margin: 0;
  padding: 0;
}

body {
  background-color: var(--background-color);
  color: var(--color);
}
$transition: 1s linear;
body {
  //transition: background-color $transition, color $transition;
}

#app {
  margin: 0;
  box-sizing: border-box;
  //transition: background-color 0.1s ease;
  //transition: color 0.1s ease;
}

.headline {
  white-space: nowrap;
  display: inline-block;
  //padding-right:4px;
}

.title {
  display: flex;
  vertical-align: top;
}

.text {
  white-space: pre-wrap;
}
</style>
