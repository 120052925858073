<template>
  <div
    class="player"
    @mousemove="hover"
    @touchmove="hover"
    @mouseleave="lastMove = 0"
    :class="{
      hover: hideTime - hideDelay < lastMove || !isPlaying,
      fullscreen: fullscreenComputed
    }"
  >
    <div class="overlay loading" v-if="loading">
      <div class="spinner" />
    </div>
    <div class="overlay" @click="togglePlay" @dblclick="atFullscreen">
      <div class="top">
        <div class="info" v-if="fullscreenComputed">
          <slot name="info" v-bind="{ isPlaying }" />
        </div>
        <div class="playlist" v-if="fullscreenComputed">
          <slot name="sidebar" v-bind="{ isPlaying }" />
        </div>
        <!--<div class="playpauselayer">
          <div class="wrap">
            <div
              class="playpause"
              @click="togglePlay"
              :class="{ paused: isPlaying }"
            ></div>
          </div>
        </div>-->
      </div>
      <div class="controls hoverItem" @click.stop v-if="!isIphone">
        <div
          class="playpause"
          @click="togglePlay"
          :class="{ paused: isPlaying }"
        ></div>
        <!--<div
            class="progress"
            :max="duration"
            :value="currentTime"
            @click="seek"
          />--><ProgressBar
          :max="duration"
          :value="currentTime"
          @seek="
            time => {
              timeComputed = time;
            }
          "
        />
        <!--<quality-select />-->
        <div class="fullscreenIcon" @click="atFullscreen"></div>
      </div>
    </div>

    <video
      :autoplay="autoplay"
      :src="src"
      :controls="isIphone"
      ref="video"
      @load="atLoad"
      @play="atPlay"
      @timeupdate="atTimeupdate"
      @canplay="atCanplay"
      @ended="atEnd"
      @canplaythrough="loading = false"
      @waiting="loading = true"
    ></video>
  </div>
</template>

<script>
import ProgressBar from "./ProgressBar.vue";
export default {
  components: { ProgressBar },
  name: "Player",
  props: {
    msg: String,
    src: String,
    autoplay: Boolean
  },
  data() {
    return {
      lastMove: 0,
      hideTime: 0,
      hideDelay: 1500,
      isPlaying: false,
      currentTime: 0,
      loading: true,
      duration: 0,
      fullscreenInternal: false
    };
  },
  watch: {
    src() {
      this.$refs.video.load();
    }
  },
  computed: {
    isIphone() {
      return navigator.userAgent.toLowerCase().indexOf("iphone") > -1;
    },
    /**
     * @private
     */
    timeComputed: {
      get() {
        return this.currentTime;
      },

      set(e) {
        this.currentTime = e;
        this.$refs.video.currentTime = e;
      }
    },
    fullscreenComputed: {
      get() {
        return this.fullscreenInternal;
      },

      set(e) {
        this.fullscreenInternal = e;
      }
    }
  },
  mounted() {
    window.addEventListener("keydown", this.keyPress);
    this.$el.addEventListener("fullscreenchange", () => {
      // document.fullscreenElement will point to the element that
      // is in fullscreen mode if there is one. If not, the value
      // of the property is null.
      if (document.fullscreenElement) {
        this.fullscreenComputed = true;
      } else {
        this.fullscreenComputed = false;
      }
    });
  },
  methods: {
    atEnd() {
      this.$emit("ended");
    },
    atLoad() {
      if (this.autoplay) {
        this.play();
      }
    },
    keyPress({ key }) {
      this.hover();
      switch (key) {
        case " ":
          this.togglePlay();
          break;
        case "f":
          this.atFullscreen();
          break;
        case "ArrowRight":
          this.timeComputed += 10;
          break;
        case "ArrowLeft":
          this.timeComputed -= 10;
          break;
        default:
        //console.log(key);
      }
    },

    atTimeupdate() {
      this.currentTime = this.$refs.video.currentTime;
    },
    atCanplay() {
      this.duration = this.$refs.video.duration;

      if (this.started && !this.checkPlaying()) this.$refs.video.play();
    },
    play() {
      this.backdoor;
      this.$refs.video.play();
      this.isPlaying = true;
      this.hover();
    },
    pause() {
      this.$refs.video.pause();
      this.isPlaying = false;
      this.hover();
    },
    togglePlay() {
      if (this.isPlaying) {
        this.pause();
      } else {
        this.play();
      }
    },
    hover() {
      const time = new Date().getTime();
      this.hideTime = time;
      this.lastMove = time;
      setTimeout(() => {
        this.hideTime = new Date().getTime();
      }, this.hideDelay + 1);
    },
    atPlay() {
      this.isPlaying = true;
    },
    atFullscreen() {
      if (!this.fullscreenComputed) {
        if (this.$el.requestFullscreen) {
          this.$el.requestFullscreen();
        } else if (this.$el.mozRequestFullScreen) {
          /* Firefox */
          this.$el.mozRequestFullScreen();
        } else if (this.$el.webkitRequestFullscreen) {
          /* Chrome, Safari and Opera */
          this.$el.webkitRequestFullscreen();
        } else if (this.$el.msRequestFullscreen) {
          /* IE/Edge */
          this.$el.msRequestFullscreen();
        }

        if (screen.orientation) {
          screen.orientation.lock("landscape");
        }

        this.fullscreenComputed = true;

        /**
         * emited when players go fullscreen
         */

        this.$emit("fullscreen", true);
      } else {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.mozCancelFullScreen) {
          /* Firefox */
          document.mozCancelFullScreen();
        } else if (document.webkitExitFullscreen) {
          /* Chrome, Safari and Opera */
          document.webkitExitFullscreen();
        } else if (document.msExitFullscreen) {
          /* IE/Edge */
          document.msExitFullscreen();
        }

        if (screen.orientation) {
          screen.orientation.unlock();
        }

        this.fullscreenComputed = false;

        /**
         * emited when players exits fullscreen
         */
        this.$emit("fullscreen", false);
      }
    }
  }
};
</script>

<style scoped lang="scss">
@import "@/assets/mixins";
$hoverColor: darken(black, 10%);
$overlayTransition: opacity 0.3s ease;
$buttonTransition: all 0.2s ease;
//$//Ctransition: all 0.2s ease;

$size: 24px;
$gap: 5px;
$height: $size + $gap * 2;
$thinkness: 5px;
$arrowspace: 18px;
//var(--color): white;

$dist: ($size - $arrowspace)/2;
$distHover: $dist * 1.5;

.player {
  background: black;
  position: relative;
  overflow: hidden;
  &.hover {
    .overlay {
      cursor: initial;
      opacity: 1;
      transition-delay: 0s;
    }
  }

  .controls {
    padding: $gap/2 0;
  }

  &.fullscreen {
    .controls {
      padding: $gap/2 $gap;
    }
  }
  max-height: 100%;
  max-width: 100%;
  height: auto;

  //display: flex;
  //justify-content: center;
  video {
    width: 100%;
    max-width: 100%;
    max-height: 100%;
    /*display: block;*/
    object-fit: contain;
  }
  .overlay {
    cursor: none;
    user-select: none;
    transition: $overlayTransition;
    transition-delay: 0.25s;
    z-index: 100;
    @include overlay;
    opacity: 0;

    .top {
      height: calc(100% - #{$height});
      position: relative;
      display: flex;

      .is-invisible {
        opacity: 0;
      }
      .playpauselayer {
        @include overlay;
        pointer-events: none;
        display: flex;
        align-items: center;
        justify-content: center;
        .wrap {
          pointer-events: all;
          z-index: 100;
          background: var(--background-color);
          padding: $size/4;
          height: $size;
          width: $size;
          display: flex;
          justify-content: center;
        }
      }
      .info {
        overflow-y: auto;
        max-width: 600px;
        overflow-x: hidden;
        padding-right: 1em;
        /*        margin: 0;
        margin-right: auto;
        padding: 0;
        display: inline-block;
        vertical-align: top;
        text-align: left;*/
      }

      .playlist {
        min-width: calc(min(30%, 250px));
        max-width: calc(min(30%, 250px));

        margin-left: auto;
        display: inline-block;
        max-height: 100%;
        overflow-y: auto;
        overflow-x: hidden;

        //flex-grow: 0.3;
        font-size: 1rem;
        * {
          //background-color: var(--background-color);
        }
      }
    }
  }

  .controls {
    box-sizing: border-box;
    background: var(--background-color);
    justify-content: space-between;
    display: flex;
    //padding: $gap/2;
    height: $height;
    z-index: 100;
    position: relative;
    > div {
      margin: $gap/2 0;
    }
    div + div {
      margin-left: $size / 4;
    }
    .progress .bar1 {
      filter: brightness(15);
    }

    cursor: initial;
    .fullscreenIcon {
      cursor: pointer;
      align-self: flex-end;
      width: $size;
      height: $size;
      //background: var(--color);
      position: relative;
      box-sizing: border-box;
      border: $thinkness solid var(--color);
      &:hover {
        &:after {
          left: $distHover;
          right: $distHover;
        }
        &:before {
          top: $distHover;
          bottom: $distHover;
        }
      }

      &:after,
      &:before {
        content: "";
        position: absolute;
        //C transition: $buttonTransition;
        background-color: var(--background-color);
      }

      &:after {
        top: -$thinkness;
        bottom: -$thinkness;
        left: $dist;
        right: $dist;
      }

      &:before {
        top: $dist;
        bottom: $dist;
        left: -$thinkness;
        right: -$thinkness;
      }
    }
  }

  .playpause {
    transition: $buttonTransition;
    border: 0;
    box-sizing: border-box;
    width: 0;
    height: $size;
    border-color: transparent transparent transparent var(--color);
    //C transition: $buttonTransition;
    cursor: pointer;

    // play state
    border-style: solid;
    border-width: $size/2 0 $size/2 $size * 0.9;

    &.paused {
      border-style: double;
      border-width: 0px 0 0px $size * 0.9;
    }

    &:hover {
      border-color: transparent transparent transparent var(--color);
    }
  }
}

.overlay.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
}

.spinner {
  display: inline-block;
  width: 3rem;
  height: 3rem;
  position: relative;
}
.spinner:after {
  content: " ";
  display: block;
  width: 100%;
  height: 100%;
  margin: 5px;
  border-radius: 50%;
  border: 5px solid #fff;
  border-color: #fff transparent #fff transparent;
  animation: spinner 1.2s linear infinite;
}
@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media screen and (min-width: 320px) and (max-width: 767px) and (orientation: landscape) {
  html {
    transform: rotate(-90deg);
    transform-origin: left top;
    width: 100vh;
    overflow-x: hidden;
    position: absolute;
    top: 100%;
    left: 0;
  }
}
</style>
