<template>
  <div class="progress" @click="seek" @mousemove="seek" @touchmove="seek">
    <div class="bar1" :style="{ width: width * (value / max) + 'px' }"></div>
  </div>
</template>
<script>
export default {
  name: "ProgressBar",
  props: {
    max: Number,
    value: Number
  },
  data() {
    return {
      width: 0
    };
  },
  methods: {
    setWidth() {
      this.width = this.$el.clientWidth;
      //console.log("set", this.width);
    },
    seek(e) {
      if (e.buttons || e.type == "click") {
        var rect = this.$el.getBoundingClientRect();
        const x = (e.clientX - rect.left) / rect.width; // + rect.width; //x position within the element.
        this.$emit("seek", this.max * x);
      }
      // console.log(e);
    }
  },
  computed: {},
  mounted() {
    this.$nextTick(() => {
      const resizeObserver = new ResizeObserver(() => {
        this.setWidth();
        //console.log("size changed");
      });
      resizeObserver.observe(this.$el);
      this.setWidth;
    });
  }
};
</script>
<style scoped lang="scss">
.progress {
  background: var(--background-color);
  position: relative;
  height: auto;
  width: 100%;
  .bar1 {
    //C transition: width 0.25s linear;
    position: absolute;
    height: 100%;
    background: var(--color);
    left: 0;
  }
}
</style>
