<template>
  <div class="films">
    <router-link
      v-for="film in $store.getters.selectedProgram.films"
      :to="{
        name: 'Work',
        params: { film: film.id, program: $store.getters.selectedProgram.title }
      }"
      :style="{
        '--item-background-color': film.backgroundColor,
        '--item-color': film.color,
        fontFamily: film.fontFamily
      }"
      :key="film.id"
      class="film"
    >
      <small style="float:right; margin-right:-.5em;">
        {{ film.duration }}</small
      >

      <p class="filmtitle">
        <span>{{ film.title }}</span>
      </p>
      <p v-if="film.artist">{{ film.artist }}</p>
      <!-- <p>{{ film.year }}</p>-->
    </router-link>
    <!--<table>
      <tbody>
      <tr v-for="(film,index) in films" :key="index">
        <td>{{ index }}</td>
        <td>{{ film.title }}</td>
        <td>{{ film.artist }}</td>
        <td>{{ film.year }}</td>

        <td class="nobreak">{{ film.duration }}</td>
      </tr>
      </tbody>
    </table>-->
  </div>
</template>

<script>
export default {
  name: "films",
  data() {
    return {};
  }
};
</script>

<style scoped lang="scss">
@import "@/assets/mixins";

.films {
  display: flex;
  flex-direction: column;
  background-color: var(--background-color);

  .film {
    /*padding-bottom: 0.5em;*/

    //&:not(:last-of-type) {
    //}
    &:not(:first-of-type) {
      margin-top: 1.5rem;
    }
    padding-right: 0.5em;
    //padding-right: 4em;
    ////C transition: all 0.2s ease;
    color: var(--color);
    background-color: var(--background-color);
    /*span {
      color: var(--item-color);
      background-color: var(--item-background-color);
    }*/
    &:hover {
      @include hover;
    }
    &.router-link-active {
      @include hover;
    }

    cursor: pointer;
    text-decoration: inherit;

    p {
      padding: 0;
      margin: 0;
      line-height: 1.25;
    }

    //padding: 1rem;
    // border: 2px solid var(--color);

    .filmtitle {
      font-size: 1rem;
      font-weight: bold;
    }
  }
}

.nobreak {
  white-space: nowrap;
}

.center {
  text-align: center;
}
</style>
