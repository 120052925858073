import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import store from "../store";
import Work from "../views/Work.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: "/:program",
    name: "Program",
    redirect: ({ params: { program } }) => {
      return {
        name: "Work",
        params: {
          program,
          film: store.state.programs.find(p => p.title === program).films[0].id
        }
      };
    }
  },
  {
    path: "/:program/:film",
    name: "Work",
    props: true,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: Work //() => import(/* webpackChunkName: "about" */ "../views/Work")
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
