<template>
  <nav class="program">
    <router-link
      class="program-link"
      :style="{
        fontFamily: program.films[0].fontFamily
      }"
      :to="{
        name: 'Program',
        params: {
          program: program.title,
          film: undefined
        }
      }"
      v-for="(program, index) in $store.state.programs"
      :key="index + program.title"
      ><span>{{ program.title }}</span></router-link
    >
  </nav>
</template>
<script>
export default {
  name: "Programs"
};
</script>
<style scoped lang="scss">
@import "@/assets/mixins";

nav {
  font-size: 2em;
  font-weight: bold;
  display: flex;
  align-items: flex-start;
  flex-flow: column wrap;

  a:last-child {
    // fixes weird stripe where the background looks through
    margin-right: -0.5px;
  }
  .program-link {
    margin-right: 0.25em;
    display: flex;
    align-self: flex-start;
    &:before {
      content: "/";
      font-family: initial;
      color: inherit;
      margin-right: 0.25em;
    }
    &:hover span {
      @include hover;
    }

    &.router-link-active span {
      @include selected;
    }
    color: inherit;
  }

  &:not(.responsive) {
    .program-link {
      &:first-child {
        &:before {
          display: none;
        }
      }
    }
  }
  @media screen and (min-width: 800px) {
    &.responsive {
      flex-flow: row wrap;
      align-items: center;
      .program-link {
        &:first-child {
          &:before {
            display: none;
          }
        }
      }
    }
  }
}
</style>
