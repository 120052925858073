<template>
  <div class="home">
    <programs class="responsive" />
  </div>
</template>

<script>
import programs from "../components/programs.vue";
// @ is an alias to /src

export default {
  components: { programs },
  name: "Home"
};
</script>
<style scoped>
.home {
  display: flex;
  height: 90vh;
  justify-content: center;
  align-items: center;
  width: 100%;
}
</style>
